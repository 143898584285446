.eyecatcher {
	@include media-breakpoint-up(xl) {
		margin-top: -($grid-gutter-width * 3.66);
	}

	&.large {
		position: relative;
	}

	.owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						position: relative;

						&::after {
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background: linear-gradient(180deg, rgba(0, 0, 0, 0.59), transparent);
						}
					}
				}
			}
		}

		.owl-dots {
			.owl-dot {
				&.active {
					span {
						background: $primary;
					}
				}

				span {
					width: 30px;
					height: 6px;
					border-radius: 0;
					background: white;
					border: none;
					box-shadow: none;
				}
			}
		}
	}
}
