.card-grid.collection.grid,
.collection.grid {
	.grid-items {
		.item {
			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			.card {
				box-shadow: $box-shadow;
				border: none;
				border-radius: 0;
				overflow: unset;

				&-image {
					.card-image-link {
						.card-image-caption {
							width: 100px;
							height: 100px;
							overflow: hidden;
							background: $tertiary;
							transform: rotate(45deg);
							right: 35px;
							bottom: -5px;
							left: auto;

							.card-image-label {
								position: absolute;
								margin: auto;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								background: transparent;
								transform: rotate(315deg);
								color: white;
								font-size: 0.9375rem;
								font-weight: $font-weight-regular;
								white-space: normal;
								display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
							}
						}
					}
				}

				&-body {
					padding: $grid-gutter-width / 1.2;
					min-height: 0;
					background: transparent;

					.card-caption {
						.card-title-link {
							margin: 0;
						}

						.card-title {
							position: absolute;
							top: 20px;
							left: 20px;
							color: white;
							font-size: $font-size-base * 1.0625;
							font-family: $font-family-atma;
							font-weight: $font-weight-medium;
							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 1.875;
							}
						}

						.card-description {
							max-width: 265px;
							line-height: 1.3;
							@include media-breakpoint-up(sm) {
								max-width: 417px;
							}

							.list-icons {
								padding: 0;

								li::before {
									display: none;
								}
							}

							&-content {
								display: block;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 4;
								-webkit-box-orient: vertical;
							}

							ul {
								margin-top: $grid-gutter-width / 1.2;

								li {
									font-size: $font-size-base_sm;
								}
							}
						}
					}

					.card-buttons {
						display: flex;
						justify-content: flex-end;
						margin-top: -($grid-gutter-width / 2);

						.card-btn {
							visibility: hidden;
							position: relative;

							&::before {
								content: "\f061";
								font-family: $font-awesome;
								visibility: visible;
								position: absolute;
								top: 0;
								right: 0;
								padding: 6px 8px;
								border-radius: 6px;
								border: 1px solid $primary;
								font-size: $font-size-base * 1.25;
								font-weight: $font-weight-regular;
								@extend .btn;
								@extend .btn-primary;
							}

							&:hover {
								&::before {
									border-color: $primary;
								}
							}
						}
					}

					.card-subtitle {
						display: none;
					}
				}
			}
		}
	}
}

&.home {
	.card-grid.collection.grid {
		.grid-items {
			.item {
				.card {
					&-body {
						padding: 0;

						.card-description,
						.card-buttons,
						.card-subtitle {
							display: none;
						}
					}
				}
			}
		}
	}
}

.card.card-assortiment {
	.card-caption {
		.card-prices {
			font-size: $font-size-base_sm !important;
			font-weight: $font-weight-medium !important;
			margin-top: $grid-gutter-width / 1.2;

			.card-price {
				padding-bottom: $grid-gutter-width / 6;
			}
		}
	}
}

.highlight {
	.item {
		.card {
			border: none;
			background: transparent;

			&-image, &-body {
				background: transparent;
			}

			&-body {
				display: none !important;
			}
		}
	}
}

.footer {
	.vocabulary-term-collection {
		.grid-items {
			.item {
				a {
					text-decoration: none;

					.card-body {
						.card-caption {
							.card-title {
								position: static;
								font-size: .875rem;
								font-weight: 400;
								font-family: "Outfit", sans-serif;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
