// new base file - january 2022

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
  background-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-navbar-default {
  background-color: transparent;
}
#TommyBookingSupport .tbs-bind.tbs-btn {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  i {
    font-family: $font-awesome;
    font-size: $font-size-base * 1.25;
    display: flex;
    justify-content: center;
  }
  .tbs-glyphicon-chevron-right {
    &::before {
      content: "\F061" !important;
    }
  }
  .tbs-glyphicon-chevron-left {
    &::before {
      content: "\F060" !important;
    }
  }
  &:hover {
    background-color: transparent;
    border-color: $primary;
    color: $primary;
  }
}

#TommyBookingSupport .tbs-btn-success {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  font-weight: $font-weight-medium;
  &::after {
    content: none;
  }
}
#TommyBookingSupport .tbs-btn-success:hover {
  background-color: white;
  border-color: $primary;
  color: $primary;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
  transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  background-color: $primary !important;
}

// include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
  padding: 0;
}
#TommyBookingSupport .tbs-navbar-collapse {
  padding: 0;
}
#TommyBookingSupport .tbs-navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

#TommyBookingSupport .tbs-navbar {
  min-height: auto;
  overflow: hidden;
}
#TommyBookingSupport * a {
  text-decoration: none;
}
#TommyBookingSupport .tbs-legenda {
  background: none;
  border: none;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
  padding: 20px 0;
  margin: 20px 0;
  background-color: transparent; // custom
  border-radius: 5px;
}
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
  margin: 33px 0;
  padding: 20px;
}
#TommyBookingSupport .tbs_overlay-mask {
  border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
  margin: 20px 0;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
  cursor: pointer;
  transition: all 0.5s ease;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
  opacity: 0.8;
}
#TommyBookingSupport .tbs-calendar table > thead > tr > th {
  border: none;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  color: #fff;
  background-color: #105251;
}

#TommyBookingSupport .tbs-row h2 {
  margin-bottom: 0;
}
#TommyBookingSupport .tbs-btn-block + .tbs-btn-block {
  margin: 0;
}
#TommyBookingSupport .tbs_overzicht .tbs-row h2 {
  margin-bottom: 15px;
}

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row {
  margin-top: 15px;
}

#TommyBookingSupport .tbs-alert-danger {
  color: #e91e63;
  background-color: rgba(233, 30, 99, 0.09);
  border: none;
  margin: 0 15px 15px;
  padding: 10px 35px 10px 10px;
}
#TommyBookingSupport .tbs-has-error .tbs-form-control {
  border-color: rgb(244, 67, 54);
}

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row {
  margin-bottom: 15px;
}
#TommyBookingSupport .tbs-label {
  font-size: 100%;
  margin-right: 5px;
}
#TommyBookingSupport .tbs_kassabon .tbs-hr {
  border-top: 2px solid $quill-grey;
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal {
  line-height: 10px;
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate {
  font-weight: $font-weight-bold !important;
  font-size: $font-size-base !important;
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount {
  font-size: $font-size-base;
}
#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row {
  margin: 0 0 15px 0;
}

#TommyBookingSupport {
  .tbs-navbar-default {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    margin-bottom: 0;
    .tbs-navbar-collapse {
      display: flex !important;
      .tbs-navbar-nav {
        background-color: white;
        padding-left: 0;
        margin-bottom: 0;
        li {
          margin-bottom: 0;
          &::before {
            content: none;
          }
          a {
            color: $secondary;
            font-size: $font-size-base_sm;
            font-weight: $font-weight-bold;
          }
          &:not(:nth-last-child(2)) {
            &::after {
              content: "";
              border-right: 2px solid $secondary;
              height: 80%;
              width: 2px;
              position: absolute;
              right: 0;
              top: 4px;
              opacity: 0.8;
            }
          }
          &.tbs-active,
          &:hover {
            a {
              background-color: $secondary;
              color: white;
            }
          }
        }
      }
    }
  }
  .tbs-page {
    .tbs-translate {
      font-size: $font-size-base_sm;
      font-weight: $font-weight-regular;
    }
    .tbs_persoonscategorien {
      > .tbs-col-xs-12 {
        margin-bottom: $grid-gutter-width / 3;
      }
    }
    .tbs-form-control {
      color: black;
      font-weight: $font-weight-regular;
      border: none;
      border-radius: 0;
    }
    .tbs_kalenders.tbs-mg-t-30 {
      .tbs-col-xs-12 {
        .tbs-row {
          .tbs-col-xs-3 {
            @include media-breakpoint-up(sm) {
              @include make-col(1);
              width: 8.33333%;
            }
          }
          .tbs-col-xs-6 {
            @include media-breakpoint-up(sm) {
              @include make-col(10);
              width: 83.33333%;
            }
          }
        }
        ul {
          padding-left: 0;
          margin-bottom: 0;
          li {
            margin-bottom: 0;
            &::before {
              content: none;
            }
          }
        }
      }
    }
    .tbs-calendar {
      .tbs-table {
        thead {
          tr {
            th.tbs-translate {
              border-color: $quill-grey;
              border-style: solid;
              border-width: 2px 0;
            }
            th.tbs-month {
              font-weight: $font-weight-regular;
              font-size: $font-size-base_sm;
            }
          }
        }
      }
      .tbs-day {
        border: none;
        border-radius: 0;
        div {
          font-size: $font-size-base_sm;
          font-weight: $font-weight-regular !important;
          color: $secondary;
        }
      }
      .tbs-day.tbs-beschikbaar.tbs-selected div {
        color: black !important;
      }
    }
    .tbs-legenda .tbs-day {
      width: 17px;
      height: 17px;
      border-radius: 0;
      &::after {
        top: -5px;
        left: 5px;
      }
    }
  }
  .tbs_kassabon {
    margin: 0;
    .tbs-totaal {
      .tbs-translate,
      .tbs-amount {
        font-size: $font-size-base_sm !important;
        font-weight: $font-weight-bold !important;
        line-height: 20px;
      }
    }
    .tbs-btn-success {
      max-width: 190px;
      margin: auto;
    }
    .tbs-accommodatie {
      font-size: $font-size-base_sm;
      font-weight: $font-weight-bold !important;
    }
    .tbs_kassabon_overzicht {
      .tbs-translate,
      span {
        font-size: $font-size-base_sm;
        font-weight: $font-weight-regular;
        padding: 0;
      }
      .tbs-row {
        .tbs-col-xs-9,
        .tbs-col-xs-12 {
          padding: 0;
        }
        &.tbs-hr {
          &:last-of-type {
            .tbs-col-xs-8 {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .tbs_talen {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        margin-bottom: 0;
        &::before {
          content: none;
        }
      }
    }
  }
}
/* resonsive mobile */
@media (max-width: 767px) {
  #TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
  #TommyBookingSupport .tbs-calendarNext span.tbs-translate {
    display: none;
  }
}
