.mini-sab {
    .container {
		position: relative;
		@extend .d-flex;
        @extend .justify-content-center;
		.container-holder {
			z-index: 100;
			position: relative;
		    padding: 5px 0 15px;
			border-radius: 5px;
			background: $tertiary;
			box-shadow: $box-shadow;       
			color: #fff;
			margin-top: ($grid-gutter-width);
			@include media-breakpoint-down(sm) {
				max-width: 95%;
				margin-top: -($grid-gutter-width);
			}
			// below eyecatcher
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}
			.column {
				.page-block.title {
					position: relative;
					h1 {
						position: absolute;
						top: -100px;
						text-align: center;
						left: 0;
						right: 0;
						color: white;
					}
				}
			}	
		}
	}
}
&.home {
	.mini-sab {
		.container {
			.container-holder {
				margin-top: -($grid-gutter-width * 1.66);
			}
		}
	}
}
&.bundle-detail {
	.mini-sab {
		background-color: $harp;
	}
}