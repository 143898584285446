ul,ol {
    list-style-type: none;
    li {
        font-size: $font-size-base;
        color: $black;
        text-align: left;
        &::before {
            color: $black;
            font-size: $font-size-base_sm;
            font-weight: $font-weight-bold;
        }
    }
}

// custom-list-extra
.custom-list-extra {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 30px;
    li {
        position: relative;
        margin-bottom: 5px;
        &::before {
            content: "\f111";
            position: absolute;
            top: 30%;
            left: -18px !important;
            font-family: $font-awesome;
            font-size: $font-size-base / 2;
            font-weight: $font-weight-bold;
            opacity: 0.8;
         }
    }
}
// ol
ol {
    padding-left: 0;
    list-style: none;
    counter-reset: li;
    li {
        margin-bottom: 5px;
        &::before {
            counter-increment: li;
            content: counter(li) ".";
            margin-right: 10px;
            margin-bottom: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: $font-weight-regular;
         }
    }
}
// custom-list
.custom-list {
    padding-left: 20px !important;
    list-style-type: none;
    li {
        margin-bottom: 5px;
        padding-left: 5px;
      
        &::before {
            content: "\f00c" !important;
            font-family: $font-awesome;
            font-size: $font-size-base_sm !important;
            left: 0 !important;
            top: 10% !important;
            margin-right: $grid-gutter-width / 3;
        }
    }
}

.bg-dark {
    .custom-list,
    .custom-list-extra,ol,ul {
        li {
            color: white !important;
            &::before {
                color: white !important;
            }
        }
    }
    a {
        color:$primary;
        &:hover {
            color: darken($color: $primary, $amount: 15%);
        }
    }
}
.main {
    ul {
        @extend .custom-list-extra;
    }
    .bg-dark {
        ul {
            li {
                color: white;
            }
        }
    }
}