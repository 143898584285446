// headings
h1 {
    font-size: $h2-font-size;
    @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
    }
}
h2 {
    font-size: $font-size-base * 1.875;
    font-weight: $font-weight-medium;
    @include media-breakpoint-up(sm) {
        font-size: $h2-font-size;
    }
}
h1,h2,h3,h5 {
    font-family: $font-family-atma;
    font-weight: $font-weight-medium;
    color: $secondary;
}
h3,h5 {
    font-weight: $font-weight-bold;
}
h4 {
    font-weight: $font-weight-regular;
    color: $tertiary;
}
h6 {
    font-weight: $font-weight-regular;
}
p {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    color: black;
}

// img
img {
    max-width: 100%;
    height: auto;
}

// links
a{
	&.nav-link{
		color:$white!important;
	}
}
a {
	color: $primary!important;
	text-decoration: none;

	&:hover {
		color: black!important;
	}
}

.bg-dark{
	a:hover{
		color:white!important;
	}
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
    transition: 0.5s !important;
}

// form
.form {
    padding: 15px;
    @include media-breakpoint-up(xl) {
        padding: 30px;
    }
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

//gallery
.gallery {
    &-item {
        .gallery-link,.gallery-caption {
            box-shadow: $box-shadow;
        }
    }
}
