// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
@import url('https://fonts.googleapis.com/css2?family=Atma:wght@500;700&display=swap');
$font-family-atma: 'Atma', cursive;
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
$font-family-kanti: 'Kanit', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500&display=swap');
$font-family-outfit: 'Outfit', sans-serif;
$font-family-base: $font-family-outfit;
$font-awesome: "Font Awesome 5 Pro";

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// colors
$primary: #B8336A;
$secondary: #044A4A;
$tertiary: #35847C;
$quaternary: #044389;
$soft-peach: #F0F0EA;
$harp: #EAF0F0;
$county-green: #093D19;
$sea-mist: #c3d7d7;
$quill-grey: #d3d3d3;
$aqua-haze: #F3F3F3;

// matrix-colors
$matrix-color-primary: #B8336A;
$matrix-color-secondary: #044A4A;
$matrix-color-tertiary: #35847C;
$matrix-color-quaternary: #044389;


// typography
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base_sm !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size: $font-size-base_sm !default;

/*carousel*/

// $carousel-min_height: 48vh !default;
$carousel-min_height--lg: 90vh !default;
body {
  &.default {
    overflow-x: hidden;
  }
}
