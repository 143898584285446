.btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 30px;
    font-size: $font-size-base_sm;
    font-weight: $font-weight-medium;
    border-radius: 6px;
    text-decoration: none !important;
    line-height: 1;
    transition: 0.3s;

    /* btn-primary */
    &.btn-primary {
        background: $primary;
        border-color: $primary;
        color: white!important;
        &:hover {
            background: $white;
            border-color: $primary;
            color: $primary!important;
        }
    }

    /* btn-outline-primary */
    &.btn-outline-primary {
		border-color: $primary;
		color:$primary!important;
        &:hover {
			color: $white!important;
			background-color: $primary;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        background-color: $secondary;
        border-color: $secondary;
        color: white!important;
        &:hover {
            background-color: white;
            border-color: $secondary;
            color: $secondary!important;
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
		border-color: $secondary;
			color: $secondary!important;

			&:hover {
				background-color: $secondary;
				color: white!important;
			}
    }
}

/*bg-dark*/
.bg-dark {
    .btn {
        /* btn-primary and btn-secondary */
        &.btn-primary, &.btn-secondary {
            &:hover {
                border-color: white;
            }
        }
        /* btn-outline-primary */
        &.btn-outline-primary {
            color: white!important;
            border-color: $primary;
            &:hover {
                color: $white;
                background-color: $primary;
                border-color: $primary;
            }
        }
        /* btn-outline-secondary */
        &.btn-outline-secondary {
            color: white!important;
            border-color: $secondary;
            &:hover {
                color: $white;
                background-color: $secondary;
                border-color: $secondary;
            }
        }
    }
}

/*  btn-back
    ========================================================================== */
.btn-back {
    display: inline-block;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    text-decoration: none;
    color: black;
    &::before {
        content: "\f060";
        background: $primary;
        padding: 6px 8px;
        border-radius: 6px;
        border: 1px solid $primary;
        color: white;
        font-size: $font-size-base * 1.25;
        font-weight: $font-weight-regular;
    }
    &:hover {
        color: black;
        &::before {
            background-color: transparent;
            color: $primary;
            border: 1px solid $primary;
        }
    }
}

/*  btn-down
    ========================================================================== */
.btn-down {
    @extend .btn;
    @extend .btn-primary;
}
