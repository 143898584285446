// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
	@extend .clearfix;
}

.search-book .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left {
	background: $tertiary;
	box-shadow: 3px 3px 6px rgba($black, 0.15);
	border-radius: 0;
	@include media-breakpoint-up(lg) {
		width: 33.33% !important;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-right {
	@include media-breakpoint-up(lg) {
		width: 66.66% !important;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	background: $harp;
	box-shadow: 3px 3px 6px rgba($color: black, $alpha: 0.15);
	border-radius: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	@include media-breakpoint-up(lg) {
		flex-wrap: nowrap;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
	color: white;
	font-size: $font-size-base * 1.875 !important;
	font-family: $font-family-atma !important;
	font-weight: $font-weight-medium;
	border-bottom: none;
	padding: ($grid-gutter-width / 1.5) 0 ($grid-gutter-width);
	@include media-breakpoint-up(sm) {
		font-size: $h2-font-size !important;
	}
}

.tommy-zeb-person-categories .ng-scope {
	padding-bottom: 1px;
}

.search-book .tommy-zeb-wrapper * h2 {
	color: white;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	font-family: $font-family-base !important;
	margin-bottom: $grid-gutter-width / 2;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0 !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn-primary;
	position: relative;
	color: white !important;
	margin-right: 10px !important;

	&::before {
		font-family: $font-awesome;
		visibility: visible;
		position: absolute;
		top: 0;
		right: 0;
		padding: 6px 8px;
		border-radius: 6px;
		border: 1px solid $primary;
		font-size: $font-size-base * 1.25;
		font-weight: $font-weight-regular;
		@extend .btn;
		@extend .btn-primary;
	}

	&:hover {
		&::before {
			border-color: $primary;
		}
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-bottom: 10px;
	color: $secondary;
	font-size: $font-size-base * 1.875;
	font-weight: $font-weight-medium;
	font-family: $font-family-atma !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: $secondary;
	font-weight: $font-weight-medium;
	margin-top: $grid-gutter-width / 1.5;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
	padding: ($grid-gutter-width) ($grid-gutter-width) ($grid-gutter-width / 2) !important;
	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width * 1.33) ($grid-gutter-width / 1.2) ($grid-gutter-width / 2) ($grid-gutter-width * 1.33) !important;
	}

	.tommy-zeb-result-text {
		font-size: $font-size-base_sm;
	}

	.tommy-zeb-result-action {
		justify-content: flex-end;

		.tommy-zeb-btn.ng-binding {
			margin: 1rem 0 0 0;
		}
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
	min-width: 100%;
	width: 311px;
	@include media-breakpoint-up(lg) {
		min-width: 311px;
	}

	img {
		border-radius: 0;
		height: 100%;
		object-fit: cover;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	color: white;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	font-family: $font-family-base !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left select,
.tommy-zeb-wrapper .tommy-zeb-left input {
	color: $secondary;
	background-color: white;
	border-radius: 0;
	font-size: $font-size-base_sm;
	font-weight: $font-weight-bold;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-date {
	border-bottom: none;
}

.search-book .tommy-zeb-results-label {
	@include media-breakpoint-up(sm) {
		margin-bottom: 15px;
	}
}

@media (min-width: 1200px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
		width: 520px;
	}
}

@media (min-width: 980px) and (max-width: 1199px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		margin-right: 0;
	}
}
