@import './0_config/_00-variables-default';
@import '~framework/assets/scss/V2/0_config/_00-util-bootstrap';
@import '~framework/assets/scss/V2/0_config/_10-variables-default';
@import '~framework/assets/scss/V2/0_config/_20-variables-bootstrap';
@import '~framework/assets/scss/V2/0_config/_30-variables-override';
@import './0_config/_10-variables-override';
@import '~framework/assets/scss/V2/1_vendor/bootstrap';

// force html font size (prevent tommybooking conflict)
html {
	@import '../../../vendor/w3media/framework/assets/scss/V2/5_utilities/reset';
}

// theme within body class
body.baroness {
	@import '../../../vendor/w3media/framework/assets/scss/V2/2_layout/header';
	@import '../../../vendor/w3media/framework/assets/scss/V2/2_layout/containers';

	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/alert';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/buttons';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/cookie-consent';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/lists';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/cards';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/faq';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/form';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/recaptcha';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/loading';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/carousel';
	@import '../../../vendor/w3media/framework/assets/scss/V2/3_components/timeslot';

	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/gallery';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/collections';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/logo';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/navbar-toggler';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/site-switcher';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/menu';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/blog-filter';
	@import '../../../vendor/w3media/framework/assets/scss/V2/4_page_block/link';

	@import '../../../vendor/w3media/framework/assets/scss/V2/5_utilities/additions';
	@import '../../../vendor/w3media/framework/assets/scss/V2/5_utilities/animations';
	@import '../../../vendor/w3media/framework/assets/scss/V2/5_utilities/darkmode';
	@import '../../../vendor/w3media/framework/assets/scss/V2/5_utilities/sticky';

	// app styling
	@import './2_layout/base';
	@import './2_layout/footer';
	@import './2_layout/header';
	@import './2_layout/mini-sab';
	@import './2_layout/sandbox-demo';
	@import './2_layout/sections';
	@import './2_layout/eyecatcher';
	@import './2_layout/owl-carousel';

	@import './3_components/buttons';
	@import './3_components/lists';
	@import './3_components/card';

	@import './7_tommy/tommy.book';
	@import './7_tommy/tommy.matrix';
	@import './7_tommy/tommy.mini-search-book';
	@import './7_tommy/tommy.search-book';
}
