> footer > section {
	&.footer-outro {
		padding: ($grid-gutter-width * 2.66) 0 ($grid-gutter-width * 1.33);
		background-color: $secondary;

		.container-one-column {
			.container-holder {
				text-align: center;

				.column {
					.page-block {
						h2,
						p {
							color: white;
						}

						p {
							font-size: $font-size-base * 1.25;
							max-width: 415px;
							margin: ($grid-gutter-width/ 3) auto ($grid-gutter-width * 1.33);
						}
					}
				}

				div.vocabulary-term-list {
					justify-content: center;
					max-width: 300px;
					margin: auto;
					@include media-breakpoint-up(sm) {
						max-width: 100%;
						margin: auto auto ($grid-gutter-width * 2.5);
					}

					ul {
						li {
							width: 100%;
							margin-bottom: $grid-gutter-width;
							@include media-breakpoint-up(sm) {
								width: max-content;
								margin-bottom: 0;
							}

							a {
								display: flex;
								color: white;
								background-color: $tertiary;
								padding: ($grid-gutter-width / 3) ($grid-gutter-width * 1.33);
								border-radius: 6px;
								text-decoration: none;

								i {
									color: white;
									font-size: $font-size-base * 1.5;
									margin-right: $grid-gutter-width / 2;
									@include media-breakpoint-up(sm) {
										color: $secondary;
									}
								}

								span {
									font-size: $font-size-base_sm;
								}

								&:hover {
									background-color: darken($color: $tertiary, $amount: 5%);
								}
							}

							&:first-of-type {
								a {
									i {
										&::before {
											content: "\F095" !important;
										}
									}
								}
							}

							&:last-of-type {
								a {
									i {
										&::before {
											content: "\F0E0" !important;
											font-weight: $font-weight-semi-bold;
										}
									}
								}
							}
						}
					}
				}

				.vocabulary-term-collection.footer-grid {
					margin-bottom: $grid-gutter-width;
					@include media-breakpoint-up(sm) {
						margin-bottom: $grid-gutter-width * 2;
					}

					.grid-items {
						.item {
							flex: 0 0 280px;
							max-width: 280px;

							.card {
								display: flex;
								flex-direction: row;
								align-items: center;
								color: white;
								background: $primary !important;
								border-radius: 6px;
								padding: 0 ($grid-gutter-width / 3);
								box-shadow: none;
								border: none;
								transition: 0.3s;

								&-image {
									background: transparent !important;

									a {
										i {
											color: white;
											font-size: $font-size-base * 1.5;
											margin-right: $grid-gutter-width / 2;
											@include media-breakpoint-up(sm) {
												color: $secondary;
											}
										}
									}
								}

								&-body {
									background: transparent !important;
									padding: ($grid-gutter-width / 3) ($grid-gutter-width / 5) ($grid-gutter-width / 5);
									flex: 0 auto;

									.card-caption {
										.card-title-link {
											color: white;
											font-size: $font-size-base_sm;
											margin: 0;

											.card-title {
												position: static;
												font-size: $font-size-base_sm;
												font-weight: $font-weight-regular;
												font-family: $font-family-outfit;
											}
										}

										.card-subtitle {
											color: white;
											font-size: $font-size-base * 1.25;
											font-weight: $font-weight-regular;
											display: block;

											a {
												color: white;
												text-decoration: none;
											}
										}
									}

									.card-buttons {
										display: none;
									}
								}

								&:hover {
									background: $primary !important;
									cursor: pointer;


									.card-image {
										a {
											i {
												color: white;
											}
										}
									}
								}
							}

							&:first-of-type {
								.card-image {
									a {
										i {
											&::before {
												content: "\F095" !important;
											}
										}
									}
								}
							}

							&:last-of-type {
								.card-image {
									a {
										i {
											&::before {
												content: "\F0E0" !important;
												font-weight: $font-weight-semi-bold;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.container-three-columns {
			.container-holder {
				.column {
					&.one {
						order: 2;
						@include media-breakpoint-up(sm) {
							order: 1;
						}

						.image {
							position: relative;
							text-align: center;

							img {
								position: relative;
								top: 45px;
								width: 168px;
								height: auto;
								@include media-breakpoint-up(sm) {
									position: absolute;
									top: -50px;
								}
								@include media-breakpoint-up(lg) {
									top: -30px;
								}
								@include media-breakpoint-up(xl) {
									top: -10px;
								}
							}
						}
					}

					&.two {
						text-align: center;
						order: 1;
						@include media-breakpoint-up(sm) {
							order: 2;
						}

						.footer-text {
							line-height: 1.3;

							p {
								color: white;
								margin-bottom: 0;
							}
						}
					}

					&.three {
						margin-top: auto;
						display: none;
						order: 3;
						@include media-breakpoint-up(sm) {
							display: block;
						}

						ul.vocabulary-term-list {
							li {
								a {
									color: white;
									transition: 0.3s;

									&:hover {
										opacity: 0.7;
									}
								}

								i {
									font-size: $font-size-base * 1.875;
									color: white;
									cursor: pointer;
									transition: 0.3s;

									&:hover {
										opacity: 0.7;
									}
								}

								span {
									display: none;
								}

								&:first-of-type {
									margin-right: $grid-gutter-width;
								}
							}
						}
					}
				}
			}
		}
	}

	&.footer-socket {
		padding: ($grid-gutter-width / 2) 0 ($grid-gutter-width / 1.5);
		background-color: #F2E2DC;
		@include media-breakpoint-up(sm) {
			background-color: #F2E2DC;
		}

		.container-one-column {
			.container-holder {
				.column {
					.page-block {
						text-align: center;

						ul {
							justify-content: center;

							li {
								margin-bottom: $grid-gutter-width / 6;
								text-align: center;
								@include media-breakpoint-up(sm) {
									margin-bottom: 0;
								}

								a {
									color: $county-green;
									font-size: $font-size-base_sm;
									font-weight: $font-weight-light;
									text-decoration: none;
								}

								&:not(:last-child) {
									&::after {
										@include media-breakpoint-up(sm) {
											content: "\f068";
											font-family: $font-awesome;
											font-size: $font-size-base / 2;
											font-weight: 100;
											margin-left: 10px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
