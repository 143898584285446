.section-2,
.section-3 {
    overflow-x: hidden;
    .container-one-column {
        .container-holder {
            .column {
                .owl-carousel {
                    min-height: 712px;
                    .owl-stage-outer {
                        .owl-stage {
                            .owl-item {
                                .item {
                                    min-height: 712px;
                                    position: relative;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.59), transparent);
                                    }
                                    .owl-container {
                                        position: absolute;
                                        top: 10%;
                                        left: 5%;
                                        z-index: 1;
                                        .owl-caption {
                                            text-align: left;
                                            @include media-breakpoint-down(sm) {
                                                max-width: 320px;
                                            }
                                            .owl-title {
                                                font-size: $font-size-base * 1.875;
                                                font-family: $font-family-atma;
                                                font-weight: $font-weight-medium;
                                                color: white;
                                                text-shadow: none;
                                                @include media-breakpoint-up(sm) {
                                                    font-size: $h1-font-size;
                                                }
                                            }
                                            .owl-description {
                                                max-width: 345px;
                                                @include media-breakpoint-up(sm) {
                                                    max-width: 420px;
                                                }
                                                p {
                                                    font-size: $font-size-base * 1.25;
                                                    color: white;
                                                    line-height: 1.2;
                                                    margin-bottom: $grid-gutter-width;
                                                    @include media-breakpoint-up(sm) {
                                                        margin-bottom: 0;
                                                    }
                                                }
                                            }
                                            .owl-btn {
                                                &:hover {
                                                    color: $primary;
                                                    border-color: white;
                                                    background-color: white;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .owl-dots {
                        .owl-dot {
                            &.active {
                                span {
                                    background: $primary;
                                }
                            }
                            span {
                                width: 30px;
                                height: 6px;
                                border-radius: 0;
                                background: white;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
