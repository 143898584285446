.eyecatcher {
	&.large {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			top: 220px;
			right: 0px;
			width: 179px;
			height: 269px;
			z-index: 1;
			background: url("/images/anwb_nl.png") no-repeat;
			@include media-breakpoint-up(sm) {
				top: 115px;
				right: 20px;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						position: relative;

						&::after {
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background: linear-gradient(180deg, rgba(0, 0, 0, 0.59), transparent);
						}

						.owl-caption {
							padding: 0 15px;

							.owl-title {
								@include font-size($h2-font-size);

								@include media-breakpoint-down(xs) {
									margin-bottom: 50px;
								}
							}
						}
					}
				}
			}
		}

		.owl-dots {
			.owl-dot {
				&.active {
					span {
						background: $primary;
					}
				}

				span {
					width: 30px;
					height: 6px;
					border-radius: 0;
					background: white;
					border: none;
					box-shadow: none;
				}
			}
		}
	}
}

&.home {
	.eyecatcher,
	.eyecatcher .owl-carousel,
	.eyecatcher .owl-carousel .item {
		min-height: calc(90vh - 135px);
	}
}
