.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	@include media-breakpoint-up(xl) {
		position: fixed;
	}
	// responsive custom styling
	.page-block.menu-toggle {
		min-width: 0;
	}

	.page-block.logo {
		min-width: 0;
		margin: 0;
	}

	.page-block.site-switcher {
		margin: 0;
		flex-grow: 1;
	}

	// responsive mobile header order.
	.page-block.menu-toggle {
		order: 0;
	}

	.page-block.logo {
		order: 1;
	}

	.page-block.site-switcher {
		order: 2;
	}

	.page-block.menu {
		order: 3;
	}

	// Desktop resets
	@include media-breakpoint-up(xl) {
		.page-block.menu-toggle {
			order: unset;
		}
		.page-block.logo {
			order: unset;
		}
		.page-block.site-switcher {
			order: unset;
		}
		.page-block.menu {
			order: unset;
		}

		.page-block.site-switcher {
			display: flex;
			justify-content: flex-end;
			margin-left: 25px;
			flex-grow: 0;
		}

		.page-block.menu-toggle {
			margin: 0;
		}
	}

	.container-one-column {
		.container-holder {
			.column {
				background-color: transparent;
				height: 115px;

				&.default {
					align-items: flex-start !important;
					@include media-breakpoint-up(sm) {
						padding: ($grid-gutter-width / 1.5) ($grid-gutter-width * 1.16);
					}
				}

				.menu-toggle {
					display: flex;
					flex-grow: 1;

					.navbar-toggler {
						display: flex !important;
						justify-content: center;
						align-items: center;
						z-index: 3;
						color: white;
						background-color: $primary;
						width: 36px;
						height: 36px;
						@include media-breakpoint-up(sm) {
							margin-top: $grid-gutter-width * 1.33;
						}

						&-icon {
							i {
								font-size: $font-size-base * 1.25;
								font-weight: $font-weight-regular;
							}
						}

						&[aria-expanded="true"] {
							.navbar-toggler-icon {
								i {
									font-size: $font-size-base * 1.625;

									&::before {
										content: "\F00D";
									}
								}
							}
						}
					}
				}

				.page-block.logo {
					display: flex;
					justify-content: center;
					flex-grow: 1;
					@include media-breakpoint-up(lg) {
						position: fixed;
						left: 50%;
						transform: translateX(-50%);
					}
					@include media-breakpoint-between(sm, lg) {
						display: block !important;
					}

					img {
						width: 304px;
						height: auto;
						max-height: 100%;

						@include media-breakpoint-up(xl) {
							width: 201px;
							height: auto;
						}
					}
				}

				div.global_header_info {
					@include media-breakpoint-up(sm) {
						order: 2;
					}
				}

				ul.global_header_info {
					margin-bottom: 0;

					li {
						margin: 0 ($grid-gutter-width / 2) 0 0;

						a {
							color: white;
							text-decoration: none;
							font-size: $font-size-base_sm;
							font-weight: $font-weight-light;

							&:hover {
								color: darken($color: white, $amount: 20%);
							}

							&::before {
								font-family: $font-awesome;
								font-weight: $font-weight-bold;
							}

							span {
								display: none;
								@include media-breakpoint-up(xl) {
									display: block;
								}
							}
						}

						&:first-of-type,
						&:nth-of-type(2) {
							a {
								&::before {
									content: "\F095";
									@include media-breakpoint-up(xl) {
										content: none;
									}
								}
							}
						}

						&:last-of-type {
							a {
								&::before {
									content: "\F0E0";
									@include media-breakpoint-up(xl) {
										content: none;
									}
								}
							}
						}
					}
				}

				div.global_reserveren {
					position: relative;
					@include media-breakpoint-up(sm) {
						order: 3;
					}

					ul.global_reserveren {
						position: relative;
						@include media-breakpoint-up(sm) {
							position: absolute;
							right: 0;
							top: 40px;
							width: max-content;
							@include media-breakpoint-up(xl) {
								margin-left: -83px;
								right: auto;
							}
						}

						li {
							a {
								@extend .btn;
								@extend .btn-primary;

								&:hover {
									border-color: white !important;
								}
							}
						}
					}
				}

				.site-switcher {
					margin-top: -($grid-gutter-width / 3);
					display: none;
					order: 4;
					@include media-breakpoint-up(xl) {
						display: block;
					}

					ul {
						li {
							.dropdown-menu {
								transform: translateY(35px) !important;
								min-width: max-content;
							}
						}
					}
				}

				.side_panel.navbar-collapse.menu {
					position: fixed;
					left: 0;
					top: 0;
					width: 100vw;
					transform: translateX(-100vw);
					height: 100%;
					background: $tertiary;
					transition: all 0.5s;
					z-index: 2;
					text-align: center;
					padding: 5rem 2rem;

					&::after {
						content: "";
						position: fixed;
						bottom: 100px;
						left: 50%;
						transform: translateX(-50%);
						//background: url("/images/sidebar_logo.svg");
						width: 80px;
						height: 80px;
						@media screen and(max-width: 375px) {
							bottom: 60px;
						}
						@include media-breakpoint-up(sm) {
							bottom: 20px;
						}
						@media screen and(min-width: 1600px) {
							bottom: 60px;
						}
					}

					@include media-breakpoint-up(md) {
						transform: translateX(-500px);
						width: 90%;
						max-width: 500px;
						&::before {
							content: "";
							display: none;
							opacity: 0;
							position: fixed;
							top: 0;
							left: 500px;
							right: 0;
							background: rgba($black, 0.5);
							bottom: 0;
							width: calc(100vw - 500px);
							transition: all 0.5s;
						}
					}
					@include media-breakpoint-down(lg) {
						&.collapse {
							display: block;
						}
					}

					&.show {
						transform: translateX(0);
						transition: all 0.5s !important;

						&::before {
							display: inline-block;
							opacity: 1;
						}
					}

					&.side_panel_opened {
						.nav-item {
							&:not(.active_side_panel) {
								display: block !important; //!important was needed to override base styles
							}
						}
					}

					.side-panel-close {
						display: none;
					}

					.navbar-nav {
						width: 50%;
						flex-direction: column;
						justify-content: center;
						height: 85%;
						overflow-y: auto;
						overflow-x: hidden;
						margin: auto;
						position: relative;

						> li {
							padding: ($grid-gutter-width / 15) 0;

							a {
								font-size: $font-size-base_sm;
								font-weight: $font-weight-bold;
								color: $white;
								@include media-breakpoint-up(sm) {
									font-size: $font-size-base;
								}

								&:hover {
									text-decoration: underline;
								}
							}

							&.dropdown {
								.dropdown-toggle {
									&[aria-expanded="true"] {
										&::after {
											content: "\f106";
										}
									}

									&::after {
										content: "\f107";
										position: absolute;
										right: 0;
										top: 9px;
										display: block;
										font-size: $font-size-base;
										font-family: $font-awesome;
										font-weight: $font-weight-regular;
										border: none;
										@include media-breakpoint-up(sm) {
											right: 60px;
											font-size: $font-size-base * 1.25;
										}
									}
								}
							}

							&.nav-item.show {
								ul.dropdown-menu.show {
									position: static !important;
									transform: none !important;
									margin: 0;
									padding: 0;
									border: none;
									background: none;
									width: 50%;
									margin-left: $grid-gutter-width / 2;
									text-align: center;
									@include media-breakpoint-up(xl) {
										margin-left: $grid-gutter-width / 3.75;
									}

									.menu-side-panel-header {
										display: none;

										.go-back {
											position: relative;

											&::after {
												font-family: $font-awesome;
												font-size: $font-size-base * 1.5;
												font-weight: 400;
												border: none;
												position: absolute;
												top: 50%;
												content: "\F178" !important; // !important needed to override base css
												transform: translateY(-50%) rotate(180deg);
											}
										}
									}

									.nav-item {
										padding: 0;

										.nav-link {
											font-size: $font-size-base_sm;
											font-weight: $font-weight-light;
											color: $white;
											padding: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.sticky {
		.container-one-column {
			.container-holder {
				.column {
					background-color: $tertiary;
					height: 115px;
					align-items: end !important;

					.page-block.logo {
						top: 0;

						img {
							width: 201px;
							height: auto;
						}
					}

					ul.global_header_info {
						transform: translateY(-50px);
					}

					.site-switcher {
						transform: translateY(-40px);
					}

					div.global_reserveren {
						ul.global_reserveren {
							top: -35px;
						}
					}
				}
			}
		}
	}
}

&.home {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					.page-block.logo {
						display: flex;
						justify-content: center;
						@include media-breakpoint-up(xl) {
							flex-grow: 0 !important;
						}

						img {
							width: 304px;
							height: auto;
							max-height: 100%;

							@include media-breakpoint-up(xl) {
								width: 370px;
								height: auto;
							}
							@media screen and (min-width: 1366px) and (max-width: 1600px) {
								position: relative;
								left: 35%;
							}
						}
					}
				}
			}
		}

		&.sticky {
			.container-one-column {
				.container-holder {
					.column {
						.page-block.logo {
							flex-grow: 1 !important;

							img {
								width: 201px;
								height: auto;
							}
						}
					}
				}
			}
		}
	}
}

/*theme-demo*/
&.theme-demo {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					background-color: $secondary;
				}
			}
		}
	}
}
