&.search {
	.content-section {
		.page-block {
			&.searchbox {
				margin: 0 0 15px 0;

				.form-control {
					border-color: $black;

					&:focus {
						border-color: $black;
						outline: 0;
						box-shadow: 0 0 0 0.2rem rgba($green, .25);
					}
				}

				.search-icon {
					display: none;
				}
			}

			&.search-results {
				.search-item {
					margin-bottom: 30px;

					.search-term {
						@extend .btn;
						@extend .btn-secondary;
					}
				}

				.search-alert {
					margin-bottom: 30px;
				}

				.item {
					margin-bottom: 30px;

					.card {
						background-color: $white;

						.card-body {
							.card-buttons {
								.card-btn {
									@extend .btn-primary;
								}
							}
						}
					}
				}
			}
		}
	}
}
