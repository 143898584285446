.header {
	position: sticky !important;
	top: 0;
	left: 0;
	right: 0;

	* {
		transition: none;
	}

	.container,
	.container-fluid {
		max-width: 1920px;

		.container-holder {
			>.column {
				background-color: transparent !important;
				padding: 0 15px !important;

				align-items: stretch;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	.header-top {
		padding: 10px 0;
		background-color: $tertiary;

		@include media-breakpoint-down(sm) {
			padding: 5px 0;
		}

		@include media-breakpoint-up(xl) {
			padding: 10px 15px;
		}
	}

	.header-bottom {
		padding: 15px 0;
		background-color: $secondary;

		@include media-breakpoint-up(xl) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	// searchbox
	.searchbox {
		position: relative;
		z-index: 20;
		overflow: hidden;

		.form-control {
			position: relative;
			right: -100%;
			transition: $transition-base;

			@include media-breakpoint-down(sm) {
				max-width: 200px;
			}

			&:focus {
				border-color: $secondary;
			}

			&.show {
				right: 0;
			}
		}

		.search-icon {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			min-width: 34px;
			height: 34px;
			margin: 2px 0 2px 15px;
			color: $white;
			line-height: 34px;
			text-align: center;
			transition: $transition-base;
			cursor: pointer;

			i {
				font-weight: 400;
			}

			&.hide {
				right: -100%;
			}

			&:hover {
				color: $primary;
			}
		}
	}

	// global_header_info
	div.global_header_info {
		margin-left: 15px;

		@include media-breakpoint-down(sm) {
			margin-left: 5px;
		}

		ul {
			margin-bottom: 0;

			li {
				margin-right: 15px;
				font-size: 14px;

				a {
					color: $white !important;

					&::before {
						font-size: 16px;
						font-weight: 400;
						font-family: $font-awesome;
					}

					&:hover {
						color: $primary !important;
					}

					span {
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}

				&:first-of-type,
				&:nth-of-type(2) {
					a {
						&::before {
							content: "\F095";

							@include media-breakpoint-up(xl) {
								content: none;
							}
						}
					}
				}

				&:last-of-type {
					a {
						&::before {
							content: "\F0E0";

							@include media-breakpoint-up(xl) {
								content: none;
							}
						}
					}
				}

			}
		}
	}

	// site-switcher
	.site-switcher {
		.navbar-nav {
			.nav-item {
				.nav-link {
					padding: 0 !important;
				}

				.dropdown-menu {
					min-width: auto;
					padding: 0;

					.dropdown-item {
						padding: 0.25rem 0.5rem;

						img {
							display: block;
							max-width: unset;
						}
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			margin-right: 15px;
			padding: 0;
			border: none;
			background: $primary;
			border-radius: 6px;
			color: $white;
			text-align: center;
			font-size: 20px;
			font-weight: 700;
			transition: 0.5s;

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background: $tertiary;
			}
		}
	}

	// logo
	.logo {
		width: 200px;
		margin-right: auto;

		@include media-breakpoint-down(xs) {
			margin-right: 0;
			width: 180px;
		}

		@include media-breakpoint-down(sm) {
			margin-left: auto;
		}

		@include media-breakpoint-up(lg) {
			width: 258px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
				max-height: none !important;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.side-panel-close {
				display: none;
			}

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						padding-left: 0;
						padding-right: 0;
						color: $white !important;
						font-size: 16px;

						&:hover {
							color: $primary !important;
						}
					}

					&.active {
						>.nav-link {
							color: $primary !important;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.menu-side-panel-header {
							display: none;
						}

						.nav-item {
							font-size: 14px;

							.nav-link {
								color: $white;

								&:hover {
									color: $primary !important;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $primary !important;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.side-panel-close {
				display: none;
			}

			.navbar-nav {
				>.nav-item {
					margin-right: 20px;
					font-size: 15px;

					>.nav-link {
						padding-left: 0 !important;
						padding-right: 0 !important;
						color: $white;
						border-bottom: 1px solid transparent;

						&:hover {
							color: $white !important;
							border-bottom-color: $white;
						}
					}

					&.active {
						>.nav-link {
							color: $white !important;
							border-bottom-color: $white;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;

						.menu-side-panel-header {
							display: none;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black !important;

								&:hover {
									color: $primary !important;
								}
							}

							&.active {
								.nav-link {
									color: $primary !important;
								}
							}
						}
					}
				}
			}
		}
	}

	// global_reserveren
	div.global_reserveren {
		@include media-breakpoint-down(sm) {
			display: none !important;
		}

		ul {
			margin-bottom: 0;

			li {
				margin-right: 0;

				a {
					@extend .btn;
					@extend .btn-primary;
				}
			}
		}
	}
}
