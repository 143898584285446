/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
    margin: 0;
    padding: 6vh 0;
}

/* 	lead-section
    ========================================================================== */
    .main {
        .lead-section + .content-section {
            margin: -($grid-gutter-width * 1.33) 0 6vh;
        }
    }
.lead-section {
    padding: ($grid-gutter-width * 2.33) 0;

    .container-one-column {
        .container-holder {
            .column {
                .title,
                .page-title {
                    h1 {
                        @extend h2;
                        margin-bottom: $grid-gutter-width !important;
                    }
                    h5 {
                        font: {
                            size: $font-size-base;
                            weight: $font-weight-regular;
                            family: $font-family-atma;
                        }
                    }
                }
                .page-block {
                    p {
                         &:first-of-type {
                            color: $tertiary;
                            font-size: $font-size-base * 1.25;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }

    /* bundles */
    .info-nav {
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .align-items-center;
        @extend .justify-content-between;
        margin-bottom: 30px;
    }
    .info {
        .subtitle {
            h5 {
                font: {
                    size: $font-size-base;
                    weight: $font-weight-regular;
                    family: $font-family-atma;
                }
            }
        }
        // accommodation
        .properties {
            @extend .d-flex;
            @extend .flex-wrap;
            @extend .justify-content-center;
            @extend .list-unstyled;
            padding-left: 0;
            margin-bottom: 0;
            li {
                margin: 0 15px;
                &::before {
                    content: none;
                }
            }
            .icon {
                margin-right: 5px;
            }
            .label {
            }
        }

        // assortiment
        .discount-price {
            .amount {
                font-size: $font-size-lg;
            }
            .suffix {
                font-size: $font-size-sm;
            }
        }
        .price {
            .amount {
                font-size: $font-size-lg;
            }
            .suffix {
                font-size: $font-size-sm;
            }
        }
        .former-price {
            text-decoration: line-through;
            .amount {
                font-size: $font-size-sm;
            }
            .suffix {
                font-size: $font-size-sm;
            }
        }
        .downloads {
            .label {
            }
            .file {
            }
        }
    }
}
&.accommodation-category-overview {
    .lead-section {
        padding: ($grid-gutter-width * 2) 0 ($grid-gutter-width * 3.33);
    }
}
&.bundle-detail {
    .bundle-detail-section {
        padding: ($grid-gutter-width * 3) 0;
        .container-two-columns {
            .container-holder {
                margin-top: ($grid-gutter-width * 2.5);
                .column {
                    .back-to-overview-link {
                        position: absolute;
                        top: -($grid-gutter-width * 4);
                    }
                    .subtitle {
                        h5 {
                            font: {
                                size: $font-size-base;
                                weight: $font-weight-regular;
                                family: $font-family-atma;
                            }
                        }
                    }
                    .properties {
                        display: flex;
                        padding-left: 0;
                        margin-bottom: 0;
                        li {
                            font-size: $font-size-base_sm;
                            &::before {
                                content: none;
                            }
                            &:not(:last-of-type) {
                                margin-right: $grid-gutter-width / 2;
                            }
                            .label {
                                margin-left: $grid-gutter-width / 3;
                            }
                        }
                    }
                    .discount-price {
                        margin-bottom: $grid-gutter-width / 6;
                    }
                    .former-price {
                        margin-bottom: $grid-gutter-width / 1.5;
                    }
                    .discount-price,
                    .former-price {
                        font-size: $font-size-base_sm;
                        font-weight: $font-weight-medium;
                    }
                }
            }
        }
    }
}
&.default.bundle-category {
    .bundle-detail-section {
        background-color: transparent !important;
        padding: 0 0 6vh !important;
    }
}
/* 	content-section
    ========================================================================== */
.content-section {
    margin: 6vh 0;
    &.logo {
        margin: 0 0 ($grid-gutter-width);
       @include media-breakpoint-up(sm) {
        margin: ($grid-gutter-width) 0 ($grid-gutter-width * 1.66);
       }
        .container-one-column {
            .container-holder {
                .column {
                    .image-collection {
                        .gallery-grid {
                            justify-content: center;
                            .gallery-item {
                                flex: 0 0 100px;
                                .gallery-link {
                                    width: 100px;
                                    box-shadow: none;
                                    .gallery-caption {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 	bundle-overview-section
    ========================================================================== */
.bundle-overview-section {
    padding: ($grid-gutter-width * 2.5) 0;
    background-color: $harp;
}

/* 	bundle-detail-section
    ========================================================================== */
.bundle-detail-section {
    padding: 6vh 0;
    background-color: $harp;
    .title {
        h1 {
            @extend h2;
        }
    }
}

/*section-1*/
.section-1 {
    padding: ($grid-gutter-width * 2.66) 0;
    overflow-x: hidden;
    .container-one-column {
        margin-bottom: $grid-gutter-width * 1.33;
        .container-holder {
            .column {
                .title {
                    h1 {
                        @extend h2;
                    }
                }
            }
        }
    }
    .container-three-columns {
        .container-holder {
            .column {
                &.one {
                    @include make-col(3);
                    @include media-breakpoint-up(sm) {
                        @include make-col(2);
                    }
                }
                &.two {
                    @include make-col(9);
                    margin-bottom: $grid-gutter-width * 1.33;
                    @include media-breakpoint-up(sm) {
                        @include make-col(5);
                        margin-bottom: 0;
                    }
                    .page-block {
                        p {
                            &:first-of-type {
                                font-size: $font-size-base * 1.25;
                                color: $secondary;
                                @include media-breakpoint-up(sm) {
                                    color: $primary;
                                }
                            }
                            a {
                                margin-top: $grid-gutter-width / 3;
                                &:hover {
                                    border-color: $primary;
                                }
                            }
                        }
                    }
                }
                &.three {
                    @include media-breakpoint-up(sm) {
                        @include make-col(5);
                    }
                    .grid {
                        &-items {
                            .item {
                                @include make-col(12);
                                margin-bottom: $grid-gutter-width / 1.5;
                                .card {
                                    border: none;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                    box-shadow: none;
                                    border-radius: 0;
                                    &-image {
                                        background-color: $secondary;
                                        border-radius: 5px;
                                        padding: ($grid-gutter-width / 2.5) ($grid-gutter-width / 2.30);
                                    }
                                    &-body {
                                        padding: 0 ($grid-gutter-width / 2);
                                        .card-caption {
                                            .card-title {
                                                color: $secondary;
                                                position: static;
                                                @include media-breakpoint-up(sm) {
                                                    color: $tertiary;
                                                }
                                                font: {
                                                    size: $font-size-base_sm;
                                                    family: $font-family-atma;
                                                    weight: $font-weight-bold;
                                                }
                                            }
                                        }
                                        .card-description {
                                            font-size: $font-size-base_sm;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*section-intro-first*/
.section-intro-first {
    background-color: $soft-peach;
    padding: ($grid-gutter-width * 2.16) 0;
    @include media-breakpoint-up(sm) {
        background-color: $harp;
    }
    .container-one-column {
        margin-bottom: $grid-gutter-width * 1.33;
        .container-holder {
            .column {
                .page-title {
                    h1 {
                        @extend h2;
                    }
                }
            }
        }
    }
    .container-three-columns {
        margin-bottom: $grid-gutter-width * 2.83;
        .container-holder {
            .column {
                &.one {
                    @include make-col(4);
                    @include media-breakpoint-up(sm) {
                        @include make-col(2);
                    }
                }
                &.two {
                    @include make-col(8);
                    @include media-breakpoint-up(sm) {
                        @include make-col(8);
                    }
                    p {
                        &:first-of-type {
                            font-size: $font-size-base * 1.25;
                            color: $tertiary;
                            @include media-breakpoint-up(sm) {
                                color: $secondary;
                            }
                        }
                        a {
                            margin-top: $grid-gutter-width / 6;
                            @extend .btn-primary;
                            &:hover {
                                border-color: $primary !important;
                            }
                        }
                    }
                }
                &.three {
                    display: none;
                    @include media-breakpoint-up(sm) {
                        @include make-col(2);
                        display: block;
                    }
                }
            }
        }
    }
    .container-one-column.block {
        margin-top: $grid-gutter-width * 2.5;
        @include media-breakpoint-up(sm) {
            margin-top: $grid-gutter-width * 3.16;
        }
        .container-holder {
            .column {
                .grid {
                    &-items {
                        justify-content: flex-start;
                        .item {
                            margin-bottom: $grid-gutter-width /1.5;
                            @include media-breakpoint-up(sm) {
                                @include make-col(6);
                            }
                            .card {
                                display: flex;
                                flex-direction: row;
                                align-items: flex-start;
                                background-color: transparent;
                                box-shadow: none;
                                border-radius: 0;
                                border: none;

                                &-image {
                                    background-color: $secondary;
                                    border-radius: 5px;
                                    padding: ($grid-gutter-width / 2.5) ($grid-gutter-width / 2.30);
                                }
                                &-body {
                                    padding: 0 ($grid-gutter-width / 2);
                                    background-color: transparent;
                                    .card-caption {
                                        .card-title {
                                            color: $secondary;
                                            position: static;
                                            @include media-breakpoint-up(sm) {
                                                color: $tertiary;
                                            }
                                            font: {
                                                size: $font-size-base_sm;
                                                family: $font-family-atma;
                                                weight: $font-weight-bold;
                                            }
                                        }
                                    }
                                    .card-description, .card-description-content, .card-description-content p {
                                        font-size: $font-size-base_sm;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*section-4*/
.section-4 {
    padding: ($grid-gutter-width * 2.16) 0;

    .container-one-column {
        margin-bottom: $grid-gutter-width * 1.33;
        .container-holder {
            .column {
                .title {
                    h1 {
                        @extend h2;
                    }
                }
            }
        }
    }
    .container-three-columns {
        .container-holder {
            .column {
                &.one {
                    @include make-col(3);
                    @include media-breakpoint-up(sm) {
                        @include make-col(2);
                    }
                }
                &.two {
                    @include make-col(9);
                    @include media-breakpoint-up(sm) {
                        @include make-col(5);
                    }
                    .page-block {
                        p {
                            &:first-of-type {
                                font-size: $font-size-base * 1.25;
                                color: $tertiary;
                                @include media-breakpoint-up(sm) {
                                    color: $secondary;
                                }
                            }
                            &:not(:nth-of-type(2)) {
                                margin-bottom: $grid-gutter-width / 6;
                            }
                            span {
                                color: $secondary;
                                font-size: $font-size-base_sm;
                                font-family: $font-family-atma;
                                font-weight: $font-weight-bold;
                                @include media-breakpoint-up(sm) {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                &.three {
                    background-color: $tertiary;
                    border-radius: 5px;
                    padding: ($grid-gutter-width * 1.33) ($grid-gutter-width / 1.5);
                    margin-left: 20px;
                    max-width: 90%;
                    @include media-breakpoint-up(sm) {
                        @include make-col(5);
                        padding: ($grid-gutter-width * 1.33) ($grid-gutter-width);
                        margin-left: 0;
                    }
                    .page-block {
                        p {
                            color: white;
                            max-width: 345px;
                            &:first-of-type {
                                font-size: $font-size-base * 1.5;
                                font-family: $font-family-atma;
                                font-weight: 500;
                                @include media-breakpoint-up(sm) {
                                    font-size: $font-size-base * 1.875;
                                }
                            }
                        }
                        &.link {
                            margin-top: $grid-gutter-width * 1.16;
                            a.link {
                                @extend .btn;
                                @extend .btn-secondary;
                                &:hover {
                                    border-color: white !important;
                                }
                            }
                        }
                    }
                    div.global_social {
                        position: absolute;
                        right: 50px;
                        bottom: 45px;
                        ul {
                            padding-left: 0;
                            margin-bottom: 0;
                            li {
                                &::before {
                                    content: none;
                                }
                               a {
                                    color: white;
                                    transition: 0.3s;
                                    &:hover {
                                        opacity: 0.7;
                                    }
                                }
                                i {
                                    font-size: $h2-font-size;
                                    color: white;
                                    cursor: pointer;
                                    transition: 0.3s;
                                    &:hover {
                                        opacity: 0.7;
                                    }
                                }
                                span {
                                    display: none;
                                }
                                &:first-of-type {
                                    margin: 0 ($grid-gutter-width / 1.5) 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*booking-section*/
.booking-section {
    background-color: $harp !important;
}

/*accommodation-search-book */
&.accommodation-search-book,
&.accommodation-book {
    .lead-section {
        padding: ($grid-gutter-width * 2.33) 0 0;
        + .content-section {
            display: none;
        }
    }
}

/* accommodation-book*/
&.accommodation-book {
    .main {
        background-color: $harp;
        .content-section {
            margin: ($grid-gutter-width) 0 0;
            padding-bottom: $grid-gutter-width * 2.66;
            &:first-of-type {
                margin-top: 0;
                padding: ($grid-gutter-width * 2.66) 0 ($grid-gutter-width / 2);
                .container-one-column {
                    .container-holder {
                        .column {
                            h1 {
                                @extend h2;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*contact*/
&.contact {
    .content-section {
        margin: ($grid-gutter-width * 3) 0 ($grid-gutter-width * 2.5);
        .container-two-columns {
            .container-holder {
                .column {
                    &.one {
                        .page-block.title {
                            h3 {
                                @extend h2;
                                margin-bottom: $grid-gutter-width / 2;
                            }
                        }
                        .page-block.wysiwyg {
                            margin-bottom: $grid-gutter-width / 2;
                            h3 {
                                font-size: $font-size-base;
                                margin-bottom: $grid-gutter-width / 6;
                            }
                            p {
                                display: flex;
                                &:first-of-type {
                                    &::before {
                                        content: "\f3c5";
                                        font-family: $font-awesome;
                                        font-size: $font-size-base;
                                        color: $secondary;
                                        margin-right: $grid-gutter-width / 3;
                                    }
                                }
                                &:nth-of-type(2) {
                                    flex-direction: column;

                                    span {
                                        display: flex;
                                        color: black;
                                        text-decoration: none;
                                        margin-bottom: $grid-gutter-width / 6;
                                        &::before {
                                            content: "\F879";
                                            font-family: $font-awesome;
                                            font-size: $font-size-base;
                                            font-weight: $font-weight-bold;
                                            color: $secondary;
                                            margin-right: $grid-gutter-width / 3;
                                        }
                                    }
                                }
                                &:nth-of-type(3) {
                                    margin-bottom: $grid-gutter-width;
                                    a {
                                        color: black;
                                        text-decoration: none;
                                        &:hover {
                                            &,
                                            &::before {
                                                color: $primary;
                                            }
                                        }
                                        &::before {
                                            content: "\F0E0";
                                            font-family: $font-awesome;
                                            font-size: $font-size-base;
                                            font-weight: $font-weight-bold;
                                            color: $secondary;
                                            margin-right: $grid-gutter-width / 3;
                                        }
                                    }
                                }
                            }
                        }
                        table {
                            tbody {
                                tr {
                                    td {
                                        padding-right: 20px;
                                    }
                                }
                            }
                        }
                        ul.vocabulary-term-list {
                            padding-left: 0;
                            margin-bottom: 0;
                            li {
                                margin: 0 ($grid-gutter-width / 1.5) 0 0;
                                &::before {
                                    content: none;
                                }
                                a {
                                    transition: 0.3s;
                                    &:hover {
                                        color: $primary;
                                    }
                                }
                                span {
                                    display: none;
                                }
                                i {
                                    font-size: $font-size-base * 1.875;
                                    color: $secondary;
                                    transition: 0.3s;
                                    &:hover {
                                        color: $primary;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                    &.two {
                        .form {
                            border: none;
                            border-radius: 0;
                            box-shadow: 3px 3px 6px rgba($black, 0.15);
                            background-color: $harp;
                            &-control {
                                border: none;
                                border-radius: 0;
                                height: 30px;
                            }
                            textarea {
                                height: auto;
                            }
                            .btn {
                                padding: 10px 40px;
                            }
                        }
                    }
                }
            }
        }
    }
    .map {
        overflow: hidden;
        .container-one-column {
            .container-holder {
                .column {
                    .page-block.script {
                        margin-bottom: -($grid-gutter-width / 3);
                    }
                }
            }
        }
    }
}

/*theme-demo*/
&.theme-demo {
    .lead-section {
        .container-one-column {
            .container-holder {
                .column {
                    display: flex;
                    flex-direction: column;
                    h1 {
                        order: 1;
                        margin-bottom: $grid-gutter-width / 1.5;
                    }
                    h5 {
                        order: 2;
                        margin-bottom: $grid-gutter-width / 2;
                    }
                    p {
                        order: 3;
                    }
                }
            }
        }
        &.bg-dark {
            background-color: $tertiary !important;
        }
    }
    .content-section {
        margin: 0 !important;
        padding: 0 0 ($grid-gutter-width * 3);
        .container-two-columns {
            .container-holder {
                .column {
                    &.one {
                        p {
                            strong {
                                color: $tertiary;
                                font-size: $font-size-base * 1.25;
                                font-weight: $font-weight-regular;
                            }
                            em {
                                font-style: normal;
                            }
                            &:last-of-type {
                                font-size: $font-size-base_sm;
                            }
                        }
                    }
                    &.two {
                        ul:first-of-type {
                            @extend .custom-list-extra;
                        }
                        p {
                            &:first-of-type {
                                a {
                                    color: black;
                                    &:hover {
                                        color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.bg-dark {
            background-color: $tertiary !important;
            .container-two-columns {
                .container-holder {
                    .column {
                        &.one {
                            p {
                                strong {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.gallery-grid
{
    .gallery-item {
        .gallery-caption {
            display: none;
        }
    }
}
